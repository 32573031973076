const React = require('react');
const PropTypes = require('prop-types');
const CenteredLayout = require('components/CenteredLayout');
const { NavLink: Link } = require('react-router-dom');
const { default: Classes } = require('./styles.scss');

const internals = {};

module.exports = class VerifyEmail extends React.PureComponent {

    static propTypes = {
        resendEmail: PropTypes.func.isRequired,
        signupDetails: PropTypes.shape({
            email: PropTypes.string,
            schoolId: PropTypes.number
        }),
        location: PropTypes.object
    }

    constructor() {

        super();
        this.state = {
            emailExist:false,
            email:''
        };

        this.resendEmail = this._resendEmail.bind(this);
    }

    componentDidMount() {

        if (this.props.location && this.props.location.state && this.props.location.state.emailExist && this.props.location.state.email){
            this.setState({
                emailExist: this.props.location.state.emailExist,
                email: this.props.location.state.email
            });
        }
    }

    _resendEmail(ev) {

        ev.preventDefault(); // Don't actually follow the link, just use an <a/> for UX

        this.props.resendEmail(this.props.signupDetails);
    }

    render() {

        const { email } = this.props.signupDetails;

        return <CenteredLayout header='Create Account' mainClass={Classes.main}>
            {this.state.emailExist ?
                <p className={Classes.emailHolder}>
                    <span className={Classes.maybeBreak}>{'It looks like you already'}</span>
                    <span className={Classes.maybeBreak}>{'started to create an account,'}</span>
                    <span className={Classes.maybeBreak}>{'so we\'ve sent you'}</span>
                    <span className={Classes.maybeBreak}>{'a new verification email to'}</span>
                    <span className={Classes.maybeBreak}><span className={Classes.email}>{this.state.email}</span></span>
                </p> :
                <p className={Classes.emailHolder}>
                    <span className={Classes.maybeBreak}>A verification email</span>
                    <span className={Classes.maybeBreak}>has been sent to</span>
                    <span className={Classes.maybeBreak}><span className={Classes.email}>{email}</span></span>
                </p>}
            <p>
                Please follow the instructions in that message to confirm your email address.
            </p>
            <p>
                {'If you haven\'t received this email within a few minutes, please check your spam folder.'}
            </p>
            <p>
                <div className={Classes.linkWrapper}>
                    <a href='' onClick={this.resendEmail}>Resend Verification Email</a>
                </div>
                {/*<div className={Classes.linkWrapper}>
                    <Link to='/signup'>Change My Email Address</Link>
                </div>*/}
                <div className={Classes.linkWrapper}>
                    <Link to='/login'>Go to Log In</Link>
                </div>
            </p>
        </CenteredLayout>;
    }
};
