const React = require('react');

const { createRef } = React;
const Each = require('lodash/each');
const SortBy = require('lodash/sortBy');
const IsEqual = require('lodash/isEqual');
const T = require('prop-types');
const { NavLink: Link } = require('react-router-dom');
const { default: Styled } = require('styled-components');

const { default: Paper } = require('@mui/material/Paper');
const { default: Divider } = require('@mui/material/Divider');
const { default: FlatButton } = require('@mui/material/Button');
const { default: Chip } = require('@mui/material/Chip');
const { default: List } = require('@mui/material/List');
const { default: ImageList } = require('@mui/material/ImageList');
const { default: ImageListItem } = require('@mui/material/ImageListItem');
const { default: ImageListItemBar } = require('@mui/material/ImageListItemBar');
const { default: Box } = require('@mui/material/Box');
const { default: UploadIcon } = require('@mui/icons-material/Publish');
const { default: SchoolIcon } = require('@mui/icons-material/School');
const { default: CameraIcon } = require('@mui/icons-material/CameraAlt');
const { default: GroupIcon } = require('@mui/icons-material/Group');
const { default: StarIcon } = require('@mui/icons-material/Stars');
const { default: SettingsIcon } = require('@mui/icons-material/Settings');
const { default: RedoIcon } = require('@mui/icons-material/Redo');
const { default: OnlineIcon } = require('@mui/icons-material/Language');
const { default: ChildIcon } = require('@mui/icons-material/ChildCare');
const { default: HomeWorkIcon } = require('@mui/icons-material/HomeWork');
const { default: RightArrowIcon } = require('@mui/icons-material/ChevronRight');
const { default: LocationCity } = require('@mui/icons-material/LocationCity');
const { default: ArrowBack } = require('@mui/icons-material/ArrowBack');
const { default: TimeLapse } = require('@mui/icons-material/Timelapse');
const { default: Crop } = require('@mui/icons-material/Crop');
const { default: EditIcon } = require('@mui/icons-material/Edit');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: AddCircleRoundedIcon } = require('@mui/icons-material/AddCircleRounded');
const { default: Typography } = require('@mui/material/Typography');
const { default: FAB } = require('@mui/material/Fab');
const { transient$Props } = require('utils/styles');

const ClassListItem = require('routes/app/classes/components/ClassListItem');
const MessageBanner = require('components/MessageBanner');
const UserAchievements = require('components/UserAchievements');
const RouterLink = require('components/RouterLink');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');
const CropAvatarDialog = require('components/CropAvatarDialog');
const { getOriginalImageUrl } = require('utils/image');
const { context } = require('app-context');

const ManageGroupsDialog = require('../../containers/ManageGroupsDialog');
const NotifyUserDialog = require('../../containers/NotifyUserDialog');
const ClassSearchDialog = require('routes/app/classes/containers/ClassSearchDialog');

const ScrollPortal = require('components/ScrollPortal');

const Colors = require('styles/colors.json');
const MaterialMenu = require('components/MaterialMenu');
const UserBadge = require('containers/UserBadge');
const InterestPopper = require('containers/InterestPopper');
const GenericError = require('components/GenericError');
const Loader = require('components/Loader');
const { default: Classes } = require('./styles.scss');
const Fonts = require('styles/fonts.json');
const _capitalize = require('lodash/capitalize');

const { getSizedImageUrl } = require('utils/image');
const { analyticsTemplates } = require('utils/analytics');
const { gotoGroupLink } = require('utils/group');
const AlertDialog = require('containers/AlertDialog');

const {
    USER_ROLE_IDS,
    DEFAULT_PROFILE_IMG
} = require('utils/constants');

const {
    filterOutKeys,
    filterInKeys,
    makeSimilarityText,
    formatSimilarityText: mainFormatSimilarityText
} = require('utils/makeUsersSimilarityText');

const { makePassionsText } = require('utils/makePassionsText');
const PassionItem = require('components/PassionInterestItem');
const { default: Tooltip } = require('@mui/material/Tooltip');
const { makeRandomInterestsText } = require('utils/makeRandomInterestsText');
const { default: BrowserImageCompression } = require('browser-image-compression');
const Heic2any = require('heic2any');

const { getDevice, isDesktop } = require('../../../../../../src/utils/device');

const { default: Backdrop } = require('@mui/material/Backdrop');

const internals = {};

const MB_IN_BYTES = 1000000;

class DetailPage extends React.PureComponent {

    // TODO make a selector for the userDetails that only includes
    // profile fields that are public e.g. remove birthdate

    static propTypes = {
        saveProfilePicture: T.func,
        currentUserId: T.any,
        currentUserName: T.string,
        onClickChat: T.func,
        onClickConnect: T.func,
        onRequestDisconnect: T.func,
        onAcceptPeer: T.func,
        openAlertDialog: T.func,
        onEnableUser: T.func,
        interestsLoading: T.bool,
        badges: T.arrayOf(T.shape({
            id: T.number,
            name: T.string,
            svg: T.string,
            icon: T.any,
            label: T.string,
            userPermissions: T.string
        })),
        actingUserRole: T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            interactions: T.array,
            permissions: T.object
        }),
        userDetails: T.shape({
            user: T.shape({
                id: T.any.isRequired,
                picFile: T.object,
                picPreviewURL: T.string,
                croppedPicture: T.string,
                firstName: T.string,
                lastName: T.string,
                profileEmail: T.string,
                birthdate: T.date,
                age: T.number,
                major: T.string,
                incomingClass: T.string,
                graduatingClass: T.string,
                career: T.string,
                profession: T.string,
                interests: T.array,
                bio: T.string,
                lastActive: T.string,
                studentBio: T.string,
                studentName: T.string,
                veteran: T.bool,
                parent: T.bool,
                onlineStudent: T.bool,
                openToSocial: T.bool,
                fullTime: T.bool,
                gender: T.string,
                type: T.string,
                permissions: T.string,
                enabled: T.bool,
                hometown: T.shape({
                    name: T.string
                }),

                // These are specific to staff
                department: T.string,
                workRemote: T.bool,
                office: T.string,
                yearHired: T.string,
                title: T.string
            }),
            hometown: T.object,
            similarities: T.object,
            housing: T.object,
            school: T.string,
            interests: T.arrayOf(T.string),
            passionInterests: T.arrayOf(T.string),
            classes: T.arrayOf(T.shape({
                id: T.any,
                name: T.string,
                emojiSymbol: T.string,
                type: T.string
            })),
            peers: T.arrayOf(T.shape({
                id: T.any.isRequired,
                firstName: T.string,
                lastName: T.string,
                croppedPicture: T.string
            })),
            peerStatus: T.oneOf(['accepted', 'declined', 'waiting', 'pending', 'declinedByMe', 'declinedByThem']), // or undefined
            chatStatus: T.oneOf(['accepted', 'declined', 'waiting', 'pending', 'declinedByMe', 'declinedByThem']), // or undefined
            transfer: T.shape({
                id: T.any,
                name: T.any,
                classId: T.any
            }),
            isMe: T.bool
        }).isRequired,
        conditionalDisplayFields: T.shape({
            incomingClass: T.bool,
            graduatingClass: T.bool,
            onlineStudent: T.bool,
            housing: T.bool,
            openToSocial: T.bool,
            transfer: T.bool,
            major: T.bool,
            department: T.bool,
            career: T.bool,
            profession: T.bool,
            type: T.bool,
            parent: T.bool,
            title: T.bool
        }),
        rolePermissions: T.shape({
            id: T.number,
            roleId: T.number,
            schoolId: T.number,
            homeText: T.string,
            canViewMajor: T.bool,
            canViewHomeTown: T.bool,
            canViewCareerAspiration: T.bool,
            canViewProfession: T.bool,
            canViewDepartment: T.bool,
            canViewBadge: T.bool,
            canViewTitle: T.bool,
            canViewIncomingClass: T.bool,
            canViewGraduatingClass: T.bool,
            canViewBio: T.bool,
            canViewLiveOnCampus: T.bool,
            canViewTransfer: T.bool,
            canViewVeteran: T.bool,
            canViewOnline: T.bool,
            canViewParent: T.bool,
            canViewOpenSocial: T.bool,
            canViewTimeStatus: T.bool,
            canViewStudentName: T.bool,
            canViewStudentBio: T.bool,
            canNotify: T.bool,
            canBatchNotify: T.bool,
            canPreapprove: T.bool,
            canDisable: T.bool,
            canCreateGroups: T.bool,
            canEditGroups: T.bool,
            canManageGroups: T.bool,
            canViewWorkRemote: T.bool,
            canViewYearHired: T.bool,
            canViewOffice: T.bool
        }),
        rolesInteractions: T.arrayOf(T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            schoolId: T.number,
            canViewProfile: T.bool,
            canViewInGroup: T.bool,
            canChat: T.bool,
            canChatWithoutConnection: T.bool,
            canSeeConnections: T.bool,
            canSeeUsersGroups: T.bool,
            canSeeSurveyFields: T.bool,
            canSeeExtendedProfile: T.bool,
            allowAutoConnect: T.bool
        })),
        role: T.shape({
            id: T.number,
            name: T.string,
            label: T.string
        }),
        currentUserClasses: T.array,
        currentUserInterests: T.array,
        categories: T.array,
        goBack: T.func,
        push: T.func,
        showNotification: T.func,
        isCompany: T.bool,
        isCommunity: T.bool,
        isSchoolOnline: T.bool
    }

    constructor(props) {

        super(props);

        this.userInterestsVal = null;

        this.setUserInterestsRef = (element) => {

            this.userInterestsVal = element;
        };

        this.updateUserInterestsRef = () => {

            // Focus the text input using the raw DOM API
            if (this.userInterestsVal !== null) {
                const children = this.userInterestsVal.children;
                if (children.length) {
                    Each(children, (child) => {

                        child.removeAttribute('tabindex');
                    });
                }
            }
        };

        this.state = {
            wide: null,
            loading: true,
            similaritiesLoading: true,
            userFetchError: false,
            notifyUserOpen: false,
            searchOpen: false,
            groupManagementOpen: false,
            selectedInterest: null,
            interestAnchorRef: null,
            interestPopperOpen: false,
            shuffledPeers: [],
            alertMessage: '',
            alertTitle: '',
            similarityText: '',
            notificationText: '',
            passionsText: '',
            connectBtnDisabled: false,
            cropping: null,
            cropPosition: { x: .5, y: .5 },
            cropRotation: 0,
            cropScale: 1,
            originalPicture: props.userDetails?.user?.croppedPicture || null
        };
        // Add a mobile breakpoint ('media query listener') for use with inline styles
        this.mql = window.matchMedia('screen and (min-width: 600px)');

        this.chat = this._chat.bind(this);
        this.connect = this._connect.bind(this);
        this.handleMediaQuery = this._handleMediaQuery.bind(this);
        this.classIsTransfer = this._classIsTransfer.bind(this);
        this.disconnectWithUser = this._disconnectWithUser.bind(this);
        this.setUserEnabled = this._setUserEnabled.bind(this);
        this.openGroupManagement = this._openGroupManagement.bind(this);
        this.closeGroupManagement = this._closeGroupManagement.bind(this);
        this.openNotifyUser = this._openNotifyUser.bind(this);
        this.closeNotifyUser = this._closeNotifyUser.bind(this);
        this.handleInterestChipClick = this._handleInterestChipClick.bind(this);
        this.handleInterestPopperClose = this._handleInterestPopperClose.bind(this);
        this.shufflePeers = this._shufflePeers.bind(this);
        this.ignorePeer = this._ignorePeer.bind(this);
        this.acceptPeer = this._acceptPeer.bind(this);
        this.createFullSimilarityText = this._createFullSimilarityText.bind(this);
        this.createPassionsText = this._createPassionsText.bind(this);
        //change pics
        this.uploadPhoto = this._uploadPhoto.bind(this);
        this.choosePic = this._choosePic.bind(this);
        this.setPicFile = this._setPicFile.bind(this);
        this.startCropping = this._startCropping.bind(this);
        this.closeCropping = this._closeCropping.bind(this);
        this.doneCropping = this._doneCropping.bind(this);
        this.hasError = this._hasError.bind(this);
        this.validate = this._validate.bind(this);
        this.preparePicPreview = this._preparePicPreview.bind(this);
        this.editPhoto = this._editPhoto.bind(this);
        this.menuButtonRef = createRef();
        this.capturePhoto = this._capturePhoto.bind(this);
    }

    UNSAFE_componentWillMount() {

        this.handleMediaQuery(this.mql);
        this.mql.addListener(this.handleMediaQuery);
    }


    getRandomInterestsText = (user) => {

        /* const randomInterestsText = 'Random interests placeholder';*/

        // const interestsText = makeFirstInterestsText({
        //     passions: this.props.result.passionInterests,
        //     interests: this.props.result.interests,
        //     categories: this.props.categories,
        //     userName: this.props.result.firstName,
        //     isMe: false
        // });

        const categories = this.props.categories;

        const hasData = categories
            && user?.interests
            && user?.firstName;

        if (!hasData) {
            return '';
        }

        return makeRandomInterestsText({
            interests: { interests: user.interests },
            categories,
            userName: user.firstName,
            isMe: false
        });
    }

    _initPage() {

        // check if current user role has permission to see other role but not for user himself
        const actingRoleInteraction = this.props.rolesInteractions.find((roleInteractionItem) => {

            return roleInteractionItem.id === this.props.actingUserRole.id;
        });

        if (!this.props.userDetails.isMe && !actingRoleInteraction.canViewProfile) {
            return this.props.push(`/app/not-found`);
        }

        this.updateUserInterestsRef();

        const { peers } = this.props.userDetails;

        if (peers.length) {
            const shuffledPeers = this.shufflePeers(peers);
            this.setState({ shuffledPeers, loading: false, userFetchError: false });
        }
        else {
            this.setState({ loading: false, userFetchError: false });
        }
    }

    componentDidMount() {

        // Load the user

        if (this.props.userDetails) {
            this._initPage();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.userDetails && this.state.loading) {
            this._initPage();
        }

        if (this.state.similaritiesLoading && this.props.userDetails?.similarities || !IsEqual(prevProps?.userDetails?.similarities, this.props.userDetails?.similarities)) {
            this.createFullSimilarityText();
            this.setState({
                similaritiesLoading: false,
                originalPicture: this.props.userDetails?.user?.croppedPicture || null
            });
        }

        if (!this.state.passionsText && this.props.userDetails?.passionInterests?.length) {
            this.createPassionsText();
        }
    }

    dynamicConfirmFunction = () => { };
    dynamicCancelFunction = () => { };

    maybeHideErrorIfShown = (field, show) => {

        return () => {

            show = (typeof show === 'undefined') ? this.hasError(field) : show;

            if (!show) {
                return this.setState({ [`${field}ErrorShow`]: show });
            }
        };
    }

    setDynamicConfirmFunction(newFunc) {

        this.dynamicConfirmFunction = newFunc;
    }

    setDynamicCancelFunction(newFunc) {

        this.dynamicCancelFunction = newFunc;
    }

    componentWillUnmount() {

        this.mql.removeListener(this.handleMediaQuery);
    }

    _handleMediaQuery(mql) {

        this.setState({ wide: !!mql.matches });
    }

    _handleInterestChipClick(event, interest) {

        this.setState({
            interestAnchorRef: event.currentTarget,
            selectedInterest: interest,
            interestPopperOpen: true
        });
    }
    _handleInterestPopperClose(event) {

        this.setState({
            interestAnchorRef: null,
            selectedInterest: null,
            interestPopperOpen: false
        });
    }

    _disconnectWithUser() {

        const { user } = this.props.userDetails;

        analyticsTemplates.buttons('begin disconnect from user', 'profile: begin disconnect');

        this.setState({
            alertMessage: `Are you sure you want to disconnect from ${user.firstName}?`,
            alertTitle: `Disconnect from User`
        });
        this.setDynamicConfirmFunction(() => {

            analyticsTemplates.buttons('confirm disconnect from user', 'profile: confirm disconnect');
            this.props.onRequestDisconnect(user);
        });
        this.setDynamicCancelFunction(() => {

            analyticsTemplates.buttons('cancel disconnect from user', 'profile: cancel disconnect');
        });

        this.props.openAlertDialog();
    }

    _ignorePeer() {

        const { user } = this.props.userDetails;

        analyticsTemplates.buttons('begin ignore request from user', 'profile: begin ignore');

        this.setState({
            alertMessage: `Are you sure you want to ignore the request from ${user.firstName}?`,
            alertTitle: `Ignore User request`
        });
        this.setDynamicConfirmFunction(() => {

            analyticsTemplates.buttons('confirm ignore request from user', 'profile: confirm ignore');
            this.props.onRequestDisconnect(user);
        });
        this.setDynamicCancelFunction(() => {

            analyticsTemplates.buttons('cancel ignore request from user', 'profile: cancel ignore');
        });

        this.props.openAlertDialog();
    }

    _acceptPeer() {

        const { user } = this.props.userDetails;

        this.props.onAcceptPeer({
            id: user.id,
            firstName: user.firstName
        });
    }

    _setUserEnabled() {

        const { user } = this.props.userDetails;

        const toEnable = !user.enabled;
        const toEnableLanguage = toEnable ? 'enable' : 'disable';

        this.setState({
            alertMessage: `Are you sure you want to ${toEnableLanguage} user ${user.firstName}?`,
            alertTitle: `${toEnableLanguage.charAt(0).toUpperCase() + toEnableLanguage.slice(1)} User`
        });
        this.setDynamicConfirmFunction(() => {

            if (toEnable) {
                analyticsTemplates.buttons('enable user', 'profile: enable user');
            }
            else {
                analyticsTemplates.buttons('disable user', 'profile: disable user');
            }

            this.props.onEnableUser(user, toEnable);
        });
        this.setDynamicCancelFunction(() => {

        });

        this.props.openAlertDialog();
    }

    _chat() {

        const { userDetails, onClickChat, push } = this.props;

        if (userDetails.peerStatus === 'accepted' ||
            userDetails.chatStatus === 'accepted') {

            return push(`/app/messaging/u:${userDetails.user.id}`);
        }

        return onClickChat(userDetails.user.id);
    }

    async _connect(isAutoConnect) {

        let { notificationText } = this.state;

        if (Array.isArray(notificationText)) {
            notificationText = notificationText[0];
        }

        this.setState({ connectBtnDisabled: true });

        await this.props.onClickConnect(
            this.props.userDetails.user.id,
            null,
            isAutoConnect,
            notificationText
        );

        if (isAutoConnect) {
            this.props.showNotification(`You are now connected to ${this.props.userDetails.user.firstName}`);
            this.setState({ connectBtnDisabled: false });
        }
    }

    _classIsTransfer(class_) {

        const { transfer } = this.props.userDetails;
        return transfer && (transfer.classId === class_.id);
    }

    _openGroupManagement() {

        analyticsTemplates.buttons('manage groups', 'profile: manage groups');
        this.setState({ groupManagementOpen: true });
    }

    _closeGroupManagement() {

        this.setState({ groupManagementOpen: false });
    }

    _openNotifyUser() {

        analyticsTemplates.buttons('open notify modal', 'profile: open notify modal');
        this.setState({ notifyUserOpen: true });
    }

    _closeNotifyUser() {

        this.setState({ notifyUserOpen: false });
    }

    openClassSearch = () => {

        this.setState({ searchOpen: true });
    }

    closeClassSearch = () => {

        this.setState({ searchOpen: false });
    }

    _shufflePeers(peers) {

        let i = peers.length;
        while (i--) {
            const ri = Math.floor(Math.random() * i);
            [peers[i], peers[ri]] = [peers[ri], peers[i]];
        }

        return peers;
    }

    _uploadPhoto(ev) {

        ev.preventDefault();
        document.getElementById('upload-profile-photo').click();
    }

    _editPhoto() {

        const {
            originalPicture,
            picFile
        } = this.state;

        if (picFile) {
            this.startCropping(picFile);
        }
        else if (originalPicture) {
            const http = context.http.nearpeer;

            const url = getOriginalImageUrl(originalPicture);

            const config = { responseType: 'blob' };
            http.get(url, config).then((response) => {

                const _picFile = new File([response.data], 'tmp.jpeg');

                this.setPicFile(_picFile);
            });
        }
    }

    async _capturePhoto() {

        const { CameraButton } = internals;

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'user',
                    width: { ideal: 1280 },
                    height: { ideal: 720 }
                }
            });

            const video = document.createElement('video');
            video.srcObject = stream;
            video.autoplay = true;
            // mirror video
            video.style.transform = 'scaleX(-1)';

            const dialog = document.createElement('dialog');
            dialog.style.padding = '20px';

            const takePhotoBtn = document.createElement('button');
            takePhotoBtn.textContent = 'Take Photo';
            takePhotoBtn.className = 'primary';
            takePhotoBtn.style.cssText = CameraButton.componentStyle.rules.join('');

            const cancelBtn = document.createElement('button');
            cancelBtn.textContent = 'Cancel';
            cancelBtn.className = 'secondary';
            cancelBtn.style.cssText = CameraButton.componentStyle.rules.join('');

            dialog.appendChild(video);
            dialog.appendChild(document.createElement('br'));
            dialog.appendChild(takePhotoBtn);
            dialog.appendChild(cancelBtn);

            document.body.appendChild(dialog);
            dialog.showModal();

            takePhotoBtn.onclick = () => {

                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');

                ctx.scale(-1, 1);
                ctx.translate(-canvas.width, 0);

                ctx.drawImage(video, 0, 0);

                canvas.toBlob((blob) => {

                    const file = new File([blob], 'webcam-photo.jpg', { type: 'image/jpeg' });
                    this.setPicFile(file);

                    stream.getTracks().forEach((track) => track.stop());
                    dialog.remove();
                }, 'image/jpeg');
            };

            cancelBtn.onclick = () => {

                stream.getTracks().forEach((track) => track.stop());
                dialog.remove();
            };

            dialog.addEventListener('close', () => {

                stream.getTracks().forEach((track) => track.stop());
                dialog.remove();
            });

        }
        catch (err) {
            console.error('Error accessing webcam:', err);
            this.props.showNotification('Unable to access camera. Please click the icon to the immediate left of the URL in the address bar.');
        }
    }

    async _choosePic(e) {

        let file = e.target.files[0];

        if (file === null) {
            return;
        }

        const { name } = file;

        // Attempt to convert heic, if the img is already browser readable ignore the err.
        try {
            this.setState({ convertingProfilePic: true });

            file = await Heic2any({
                blob: file,
                toType: 'image/jpeg'
            });

            // Update extension to jpeg
            const convertedName = name.split('.')[0] + '.jpeg';

            file = new File([file], convertedName, {
                type: 'image/jpeg',
                lastModified: new Date()
            });
        }
        catch (err) {
            console.log('HEIC err', err);
            file = e.target.files[0];
        }

        try {
            // BrowserImageCompression
            file = await BrowserImageCompression(file, {
                maxSizeMB: 5,
                fileType: 'image/jpeg'
            });

            // Update extension to jpeg
            const convertedName = name.split('.')[0] + '.jpeg';

            file = new File([file], convertedName, {
                type: 'image/jpeg',
                lastModified: new Date()
            });
        }
        catch (err) {
            console.log('BrowserImageCompression err', err);
            file = e.target.files[0];
        }

        this.setState({ convertingProfilePic: false });

        this.setPicFile(file);
        e.target.value = null; // Reset so we can select the same image and still trigger an onChange
    }

    _setPicFile(picFile) {

        this.setState({ picFile }, () => {

            this.maybeHideErrorIfShown('picFile')();
            if (this.validate('picFile') !== null) {
                return this.preparePicPreview(null);
            }

            return this.startCropping(picFile);
        });
    }

    _startCropping(picFile) {

        return this.setState({ cropping: picFile });
    }

    async _doneCropping({ canvas, position, rotation, scale }) {

        this.setState({
            cropping: null,
            cropPosition: position,
            cropRotation: rotation,
            cropScale: scale
        });

        this.preparePicPreview(canvas);

        if (typeof this.props.saveProfilePicture === 'function') {
            this.setState({ uploadingProfilePic: true });
            const res = await this.props.saveProfilePicture({
                picture: this.state.picFile,
                cropPosition: position,
                cropRotation: rotation,
                cropScale: scale
            });

            this.setState({ uploadingProfilePic: false });

            if (!res) {
                this.setState({ picPreviewURL: '' });
            }
        }
    }

    _closeCropping() {

        this.setState({
            cropping: null,
            cropPosition: { x: .5, y: .5 },
            cropRotation: 0,
            cropScale: 1,
            picFile: null

        });
    }

    _hasError(field) {

        if (this.validate(field) !== null) {
            this.setState({ pageHasErrors: true });
            return true;
        }

        return false;
    }

    _validate(field) {

        const value = this.state[field];

        switch (field) {
            case 'picFile':

                if (value) {
                    const acceptedFiletypes = ['bmp', 'tif', 'tiff', 'gif', 'jpeg', 'jpg', 'jif', 'jfif', 'jp2', 'jpx', 'j2k', 'j2c', 'fpx', 'pcd', 'png'];
                    const extensionRejectionMsg = 'File must have one of these extensions: ' + acceptedFiletypes.join(', ');
                    const match = value.name.split('.').pop();

                    if (!match) {
                        return extensionRejectionMsg;
                    }

                    const ext = match.toLowerCase();

                    if (!acceptedFiletypes.includes(ext)) {
                        return extensionRejectionMsg;
                    }

                    if (value.size > (9.9 * MB_IN_BYTES)) {
                        return 'File must be under 10mb';
                    }
                }

                if (!value && !this.state.croppedPicture && !this.state.picPreviewURL) {
                    return 'Profile pic is required';
                }

                break;


        }

        return null;
    }

    _createFullSimilarityText() {

        let baseSimilarityText;
        let studentSimilarityText;

        const studentSimilarityTypes = [
            'majors',
            'transfer',
            'online'
        ];

        const {
            user,
            isMe,
            similarities
        } = this.props.userDetails;

        const { role: viewedProfileRole } = user || {};

        const isParent = USER_ROLE_IDS.PARENT.includes(viewedProfileRole.id);

        if (!isMe) {
            let baseSimilarities = '';
            let classSimilarities = '';

            if (isParent) {
                ({ baseSimilarities, classSimilarities } = makeSimilarityText({
                    similarities: filterOutKeys(similarities, studentSimilarityTypes)
                }));
            }
            else {
                ({ baseSimilarities, classSimilarities } = makeSimilarityText({
                    similarities
                }));
            }

            if (baseSimilarities || classSimilarities) {
                if (isParent) {

                    baseSimilarityText = `${baseSimilarities ?? ''} ${classSimilarities ?? ''}`;

                    const { baseSimilarities: studentBaseSimilarities } = makeSimilarityText({
                        similarities: filterInKeys(similarities, studentSimilarityTypes),
                        areStartTxt: ' are'
                    });

                    studentSimilarityText = studentBaseSimilarities;
                }
                else {
                    baseSimilarityText = `${baseSimilarities ?? ''} ${classSimilarities ?? ''}`;
                }
            }
            else {
                const userInView = {
                    firstName: this.props.userDetails.user.firstName,
                    interests: this.props.userDetails.interests,
                    id: this.props.userDetails.user.id
                };

                const curUser = {
                    firstName: this.props.currentUserName,
                    interests: this.props.currentUserInterests,
                    id: this.props.currentUserId

                };

                return this.setState({
                    similarityText: this.getRandomInterestsText(userInView),
                    notificationText: this.getRandomInterestsText(curUser)
                });
            }
        }

        const similarityText = this.formatSimilarityText(
            baseSimilarityText,
            studentSimilarityText,
            user.firstName,
            isParent
        );

        return this.setState({
            similarityText,
            notificationText: similarityText
        });
    }

    _preparePicPreview(canvas) {

        return this.setState({ picPreviewURL: canvas && canvas.toDataURL() });
    }

    _createPassionsText() {

        let basePassionText;

        const {
            user,
            isMe,
            passionInterests
        } = this.props.userDetails;

        const {
            categories
        } = this.props;

        if (passionInterests) {
            basePassionText = makePassionsText({
                passions: { passions: passionInterests },
                categories,
                userName: user.firstName,
                isMe
            });
        }

        return this.setState({
            passionsText: basePassionText
        });
    }

    renderPassionsHolder() {

        const {
            passionInterests
        } = this.props.userDetails;

        const { BigDivider } = internals;

        const { categories } = this.props;
        const { passionsText } = this.state;
        const passions = passionInterests;

        const usedCategoriesIds = Array.from(new Set(passions.map((interest) => interest.categoryId)));
        const usedCategories = categories.filter((category) => usedCategoriesIds.includes(category.id));
        const sortedCategories = SortBy(usedCategories, ['name']);

        return <React.Fragment>
            <BigDivider />
            <div className={Classes.passionsHolder}>
                {sortedCategories.map((category) => {

                    const filteredInterests = passions.filter((interest) => {

                        return interest.categoryId === category.id;
                    }).sort(this.sortInterests);

                    return filteredInterests.map((interest) => {

                        const svg = (interest && interest.svg) ? interest.svg : ((category && category.svg) ? category.svg : null);

                        if (svg) {
                            return <PassionItem key={interest.id} interest={interest} svg={svg} />;
                        }
                    });
                })}
            </div>
            <div style={{ paddingTop: 10, fontSize: 16 }}>
                <span style={{ width: '100%' }}>{passionsText}</span>
            </div>
        </React.Fragment>;
    }

    formatSimilarityText(baseSimilarityText, studentSimilarityText, peerName, isParent) {

        let fullSimilarityText = '';
        if (baseSimilarityText || studentSimilarityText) {
            if (baseSimilarityText) {
                fullSimilarityText = `${baseSimilarityText} `;
            }

            if (studentSimilarityText) {
                if (isParent) {
                    fullSimilarityText = fullSimilarityText + `Your students${studentSimilarityText}`;
                }
                else {
                    fullSimilarityText = fullSimilarityText + `You and ${peerName}'s student ${studentSimilarityText}`;
                }
            }
        }

        return mainFormatSimilarityText(fullSimilarityText);
    }

    sortInterests(a, b) {

        const aSpecial = a && a.special;
        const bSpecial = b && b.special;

        return (Number(bSpecial) - Number(aSpecial)) || (a.categoryName.localeCompare(b.categoryName)) || a.name.localeCompare(b.name);
    }

    sortPeers(peers) {

        return peers.sort((peer1, peer2) => {

            return (`${peer1.firstName.toLowerCase()} ${peer1.lastName.toLowerCase()}` < `${peer2.firstName.toLowerCase()} ${peer2.lastName.toLowerCase()}`) ? -1 : 1;
        });
    }

    render() {

        const {
            goBack,
            badges,
            currentUserClasses,
            currentUserInterests,
            rolePermissions,
            rolesInteractions,
            actingUserRole,
            showNotification,
            interestsLoading,
            isCompany,
            isCommunity,
            isSchoolOnline,
            categories,
            push
        } = this.props;

        const currentUserInterestsIds = currentUserInterests?.map((interest) => interest.id);

        // Handle case where data is still in flight
        if (this.state.loading || interestsLoading) {
            return <Loader />;
        }

        // Failed to load the requested user
        if (this.state.userFetchError || !this.props.userDetails) {
            return <GenericError />;
        }

        const {
            Wrapper,
            BigDivider,
            styles,
            getPronouns,
            HeaderTitle,
            TopRowExtras,
            PictureWrapper,
            EditIconButton,
            FlexRow,
            SpinnerContainer,
            ProfileFAB,
            EditPicButtonsContainer,
            InterestHelperText,
            StyledImageRequestBtn,
            ConnectButton,
            StyledBackdrop,
            InterestChip,
            StyledImageListItem,
            StyledImageListItemBar
        } = internals;

        const {
            user,
            school,
            interests,
            passionInterests,
            classes,
            peers,
            peerStatus,
            isMe,
            transfer,
            housing
        } = this.props.userDetails;

        const sortedGroups = SortBy(classes, ['name']);

        const actingRoleInteraction = rolesInteractions.find((item) => {

            return item.id === actingUserRole.id;
        });

        const {
            canChat: canChatPermission,
            allowAutoConnect: canAutoConnect
        } = actingRoleInteraction;

        const userBadge = badges && badges.find(({ name }) => name === user.type);

        const {
            gender = '',
            role: viewedProfileRole,
            studentName,
            studentBio
        } = user || {};

        const pronouns = getPronouns(gender);
        const capitalizedUserType = user.type && _capitalize(user.type);
        const isEnabled = user.enabled;

        const usedCategoriesIds = Array.from(new Set(
            interests.map((interest) => interest.categoryId)
        ));
        const usedCategories = categories.filter((category) => usedCategoriesIds.includes(category.id));
        const sortedCategories = SortBy(usedCategories, ['name']);

        const canChat = !isMe && isEnabled && canChatPermission && peerStatus === 'accepted';

        const canRequestPeer = !isMe && isEnabled && (!peerStatus || peerStatus === 'declinedByMe' || peerStatus === 'declinedByThem');
        const disconnectedPeer = !isMe && isEnabled && peerStatus === 'declinedByMe';
        const canDisconnect = !isMe && isEnabled && !!peerStatus && peerStatus !== 'declinedByMe' && peerStatus !== 'declinedByThem';
        const waitingForResponse = !isMe && isEnabled && (peerStatus === 'waiting');
        const pendingMyResponse = !isMe && isEnabled && peerStatus === 'pending';

        const canSeeUsersGroups = !isMe ? actingRoleInteraction.canSeeUsersGroups : true;
        const canSeeUsersConnections = !isMe ? actingRoleInteraction.canSeeConnections : true;
        // const canSeePrivateGroups = !isMe ? actingRoleInteraction.canSeePrivateGroups : true;

        const settingsMenuOptions = [];

        const isParent = USER_ROLE_IDS.PARENT.includes(viewedProfileRole.id);
        const isStudent = USER_ROLE_IDS.STUDENT.includes(viewedProfileRole.id);
        const isStaffOrSuper = USER_ROLE_IDS.STAFF.includes(viewedProfileRole.id) || USER_ROLE_IDS.SUPERUSER.includes(viewedProfileRole.id);
        const fullName = `${user.firstName} ${user.lastName}`;

        if (canDisconnect) {
            settingsMenuOptions.push({
                name: `Disconnect from ${user.firstName}`,
                action: this.disconnectWithUser
            });
        }

        if (disconnectedPeer) {
            settingsMenuOptions.push({
                name: `Connect again with ${user.firstName}`,
                action: () => {

                    this.connect(canAutoConnect);
                }
            });
        }

        if (this.props.rolePermissions) {

            if (this.props.rolePermissions.canManageGroups) {
                settingsMenuOptions.push({
                    name: `Manage ${this.props.userDetails.user.firstName}'s groups`,
                    action: this.openGroupManagement
                });
            }

            if (this.props.rolePermissions.canDisable) {
                const toEnableLanguage = isEnabled ? 'Disable' : 'Enable';

                settingsMenuOptions.push({
                    name: `${toEnableLanguage} user ${this.props.userDetails.user.firstName}`,
                    action: this.setUserEnabled
                });
            }

            if (this.props.rolePermissions.canNotify) {
                settingsMenuOptions.push({
                    name: `Notify ${this.props.userDetails.user.firstName}`,
                    action: this.openNotifyUser
                });
            }
        }

        const { connectBtnDisabled } = this.state;

        const showCameraBtn = isDesktop(getDevice());

        const titlePrefix = isMe ? 'Your' : `${user.firstName}'s`;

        let onlineClassPrefix;
        let campusPrefix;
        let studentInfoIsPrefix;

        if (isParent) {
            onlineClassPrefix = isMe ? 'Your student takes' : `${user.firstName}'s student takes`;
            campusPrefix = isMe ? 'Your student lives' : `${user.firstName}'s student lives`;
            studentInfoIsPrefix = isMe ? 'Your student is' : `${user.firstName}'s student is`;
        }
        else {
            onlineClassPrefix = isMe ? 'You take' : `${user.firstName} takes`;
            campusPrefix = isMe ? 'You live' : `${user.firstName} lives`;
            studentInfoIsPrefix = isMe ? 'You\'re' : `${user.firstName} is`;
        }

        const infoIsPrefix = isMe ? 'You\'re' : `${user.firstName} is`;

        const showInterestHelperText = !isMe && !!(interests.find(({ id }) => !currentUserInterestsIds.includes(id)));

        // All users will have user.croppedPicture set to something when viewing DetailPage
        const hasNonDefaultProfilePic = user.croppedPicture !== DEFAULT_PROFILE_IMG;

        return (
            <Wrapper>
                <AlertDialog
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    declineLabel='No'
                    confirmationCallback={() => {

                        this.dynamicConfirmFunction();
                    }}
                    cancelCallback={() => {

                        this.dynamicCancelFunction();
                    }}
                />
                {!isEnabled && <MessageBanner
                    text='This account has been closed.'
                />}
                <ScrollPortal top>
                    <Paper className={`${Classes.fixedPeerChat} contentWrapper`}>
                        {(waitingForResponse || pendingMyResponse) && <>
                            {waitingForResponse && <div className={Classes.button}>
                                <ConnectButton
                                    disabled
                                    fullWidth
                                >
                                    Waiting for their response
                                </ConnectButton>
                            </div>}
                            {pendingMyResponse && <div className={Classes.button} style={{ display: 'flex', width: '100%', flexFlow: 'column', justifyContent: 'center' }}>
                                <ConnectButton
                                    label='Pending your response'
                                    disabled
                                    fullWidth
                                >
                                    Pending your response
                                </ConnectButton>
                                <h3 style={{
                                    textAlign: 'center',
                                    marginTop: 8
                                }}>
                                    {`${user.firstName} would like to connect with you`}
                                </h3>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-evenly',
                                    marginBottom: '1rem'
                                }}>
                                    <StyledImageRequestBtn
                                        variant={'outlined'}
                                        color={'secondary'}
                                        onClick={this.ignorePeer}
                                        data-focus-outline='radius:40,padding:-1'
                                    >
                                        Ignore
                                    </StyledImageRequestBtn>
                                    <StyledImageRequestBtn
                                        variant={'outlined'}
                                        color={'primary'}
                                        onClick={this.acceptPeer}
                                        data-focus-outline='radius:40,padding:-1'
                                    >
                                        Accept
                                    </StyledImageRequestBtn>
                                </Box>
                            </div>}
                        </>}
                        {disconnectedPeer && <div className={Classes.button}>
                            <ConnectButton
                                label='Disconnected'
                                disabled
                                fullWidth
                            >
                                Disconnected
                            </ConnectButton>
                        </div>}
                        {!disconnectedPeer && canRequestPeer && <div className={Classes.button}>
                            <ConnectButton
                                disabled={connectBtnDisabled}
                                fullWidth
                                style={{
                                    ...(connectBtnDisabled ? {} : { color: 'rgb(169, 42, 84)' })
                                }}
                                onClick={() => this.connect(canAutoConnect)}
                                data-focus-outline='radius:40'
                            >
                                {canAutoConnect ? 'Auto Connect' : 'Connect'}
                            </ConnectButton>
                        </div>}
                        {!disconnectedPeer && canChat && <div className={Classes.button}>
                            <ConnectButton
                                color={'primary'}
                                fullWidth
                                $isChat
                                onClick={this.chat}
                                data-focus-outline='radius:40'
                            >
                                Chat
                            </ConnectButton>
                        </div>}
                    </Paper>
                </ScrollPortal>

                <div className={Classes.main}>
                    <TopRowExtras>
                        <IconButton
                            onClick={goBack}
                            aria-label='previous screen'
                            size='large'
                            data-focus-outline={this.state.wide ? 'radius:30,padding:-2,zIndex:1' : 'radius:30,padding:-8,zIndex:1'}
                        >
                            <ArrowBack />
                        </IconButton>
                        {(settingsMenuOptions.length !== 0) && (<MaterialMenu
                            anchorOrigin={{ horizontal: 'left' }}
                            buttonRef={this.menuButtonRef}
                            iconElement={<SettingsIcon
                                style={{ color: Colors.darkGrey }}
                                onKeyDown={(evt) => {

                                    if (evt.key === 'Enter' || evt.code === 'Space') {
                                        this.menuButtonRef.current?.click();
                                    }
                                }}
                            />}
                            menuOptions={settingsMenuOptions}
                        />)}
                    </TopRowExtras>

                    <div className={Classes.header}>
                        <PictureWrapper>
                            {!isMe && <img src={getSizedImageUrl(user.croppedPicture, 400)} alt={`${user.firstName}'s Profile Photo`} />}
                            {isMe && (
                                <>
                                    <img src={getSizedImageUrl(user.croppedPicture, 400)} alt={`${user.firstName}'s Profile Photo`} />
                                    <div
                                        className={Classes.uploadPicWrapper}
                                        style={{ backgroundImage: user.croppedPicture, marginBottom: 20 }}
                                    >
                                        <EditPicButtonsContainer>
                                            {/* Only allow resizing if picFile exists and picture is not default */}
                                            {((this.state.picFile ||
                                                (this.state.originalPicture && hasNonDefaultProfilePic)) && (
                                                <Tooltip arrow={true} title='Resize Current' placement='bottom'>
                                                    <ProfileFAB
                                                        color='primary'
                                                        size='small'
                                                        aria-label='Resize photo'
                                                        onClick={() => {

                                                            this.editPhoto();
                                                            AnimatedFocusIndicator.onBlurHandler();
                                                        }}
                                                        data-focus-outline='radius:40,borderSize:3,zIndex:1'
                                                    >
                                                        <Crop />
                                                    </ProfileFAB>
                                                </Tooltip>
                                            ))}
                                            <Tooltip arrow={true} title='Upload New' placement='bottom'>
                                                <ProfileFAB
                                                    color='primary'
                                                    size='small'
                                                    aria-label='Upload new photo'
                                                    onClick={(event) => {

                                                        this.uploadPhoto(event);
                                                        AnimatedFocusIndicator.onBlurHandler();
                                                    }}
                                                    data-focus-outline='radius:40,borderSize:3,zIndex:1'
                                                >
                                                    <UploadIcon />
                                                </ProfileFAB>
                                            </Tooltip>
                                            {showCameraBtn && (
                                                <Tooltip arrow={true} title='Take Photo' placement='bottom'>
                                                    <ProfileFAB
                                                        color='primary'
                                                        size='small'
                                                        aria-label='Take photo with camera'
                                                        onClick={(event) => {

                                                            this._capturePhoto();
                                                            AnimatedFocusIndicator.onBlurHandler();
                                                        }}
                                                        data-focus-outline='radius:40,borderSize:3,zIndex:1'
                                                    >
                                                        <CameraIcon />
                                                    </ProfileFAB>
                                                </Tooltip>
                                            )}
                                        </EditPicButtonsContainer>

                                        <StyledBackdrop open={this.state.submitting || this.state.uploadingProfilePic || this.state.convertingProfilePic}>
                                            <SpinnerContainer>
                                                <Loader />
                                            </SpinnerContainer>
                                        </StyledBackdrop>

                                        <input
                                            id='upload-profile-photo'
                                            type='file'
                                            accept='image/*,.heic'
                                            aria-label='Upload photo'
                                            onChange={this.choosePic}
                                            style={{ visibility: 'hidden', width: '0px', height: '0px' }}
                                            ref={this.picFile}
                                        />
                                    </div>
                                </>
                            )}
                        </PictureWrapper>

                        <div>
                            <div>
                                <HeaderTitle>{fullName}</HeaderTitle>
                                {userBadge &&
                                    <div className={Classes.userBadgeGroup}>
                                        {rolePermissions.canViewBadge && <UserBadge extraLarge className={Classes.userBadge} userType={user.type} userLabel={userBadge.label || capitalizedUserType} />}
                                        <span className={Classes.userBadgeLabel}>{rolePermissions.canViewBadge && (userBadge.label || capitalizedUserType)}</span>
                                    </div>
                                }
                                {!userBadge && <div>{capitalizedUserType}</div>}
                                <BigDivider />
                            </div>
                            <div className={Classes.schoolInfo}>
                                <div className={Classes.schoolLine}>{school}</div>
                                {isStudent && rolePermissions.canViewMajor && user.major && <div className={Classes.profileDetailLine}>{user.major}</div>}
                                {rolePermissions.canViewDepartment && user.department && <div className={Classes.profileDetailLine}>{user.department}</div>}
                                {isStudent && rolePermissions.canViewIncomingClass && user.incomingClass && <div className={Classes.profileDetailLine}>{`Incoming ${user.incomingClass}`}</div>}
                                {isStudent && rolePermissions.canViewGraduatingClass && user.graduatingClass && <div className={Classes.profileDetailLine}>{`Class of ${user.graduatingClass}`}</div>}
                                {rolePermissions.canViewTitle && user.title && <p className={Classes.profileDetailLine}>{user.title}</p>}
                            </div>
                        </div>

                        <CropAvatarDialog
                            open={!!this.state.cropping}
                            onClose={this.closeCropping}
                            file={this.state.cropping}
                            onDone={this.doneCropping}
                            contentClassName={Classes.cropAvatarDialog}
                        />

                    </div>

                    <UserAchievements user={user} extraUserInfo={{ peers }} className={Classes.achievementsContainer} />

                    {rolePermissions.canViewOpenSocial && !isParent && !isStaffOrSuper && <div className={Classes.openToSocialContainer}>
                        <BigDivider />
                        <div className={user.openToSocial ? Classes.openToSocial : Classes.academicOnly}>
                            {user.openToSocial && <span className={Classes.socialMessageContainer}>
                                <GroupIcon style={styles.icon} />
                                <SchoolIcon style={styles.icon} />
                                <span className={Classes.socialMessage}>Looking for both academic and social connections</span>
                            </span>}
                            {!user.openToSocial && <span className={Classes.socialMessageContainer}>
                                <SchoolIcon style={styles.icon} />
                                <span className={Classes.socialMessage}>Looking only for academic connections</span>
                            </span>}
                        </div>
                    </div>}

                    {passionInterests && passionInterests.length > 0 && this.renderPassionsHolder()}

                    {!isMe && this.state.similarityText && this.state.similarityText.length > 0 && <>
                        <BigDivider />
                        <div className={Classes.infoPairContainer}>
                            <span style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: this.state.similarityText }} />
                        </div>
                    </>}

                    {/* INFO SECTION */}

                    <div className={Classes.infoSection}>
                        <BigDivider />
                        <FlexRow style={{ marginBottom: 22 }}>
                            <div>
                                <h3>{titlePrefix} Info</h3>
                            </div>
                            {isMe ? <Tooltip
                                arrow
                                title='Edit My Profile'
                                placement='top'
                                data-focus-outline='radius:30,padding:-2,zIndex:1'
                            >
                                <EditIconButton
                                    size='large'
                                    component={RouterLink}
                                    to={{
                                        pathname: '/app/profile/edit',
                                        state: {
                                            returnTo: `/app/profile/${user.id}`
                                        }
                                    }}
                                >
                                    <EditIcon />
                                </EditIconButton>
                            </Tooltip> : null}
                        </FlexRow>
                        {rolePermissions.canViewBio && !!user.bio && <div className={Classes.infoPairContainer}>
                            <div className={Classes.oneLinerTitle}>Bio</div>
                            <span>&ldquo;{user.bio}&rdquo;</span>
                        </div>}
                        {!!user.gender && user.gender !== 'decline' && <div className={Classes.infoPairContainer}>
                            <div className={Classes.oneLinerTitle}>Pronouns</div>
                            <span>{pronouns}</span>
                        </div>}
                        {!isParent && rolePermissions.canViewLiveOnCampus && (isCompany || isCommunity || isSchoolOnline) && !!housing?.name && !housing?.onCampus && <div className={Classes.infoPairContainer}>
                            <div className={Classes.oneLinerTitle}>Lives In</div>
                            <div className={Classes.hometownWrapper}><span>{housing.name}</span></div>
                        </div>}
                        {rolePermissions.canViewHomeTown && user.hometown?.name && <div className={Classes.infoPairContainer}>
                            <div className={Classes.oneLinerTitle}>{(isCompany || isCommunity || isSchoolOnline) ? 'Grew Up In' : 'Hometown'}</div>
                            <div className={Classes.hometownWrapper}><span>{user.hometown.name}</span></div>
                        </div>}
                        {rolePermissions.canViewCareerAspiration && user.career && <div className={Classes.infoPairContainer}>
                            <div className={Classes.oneLinerTitle}>Career Aspiration</div>
                            <span>{user.career}</span>
                        </div>}
                        {rolePermissions.canViewProfession && user.profession && <div className={Classes.infoPairContainer}>
                            <div className={Classes.oneLinerTitle}>Profession</div>
                            <span>{user.profession}</span>
                        </div>}
                        {isCompany && rolePermissions.canViewOffice && user.office && <div className={Classes.infoPairContainer}>
                            <div className={Classes.oneLinerTitle}>{'Affiliated Office'}</div>
                            <span>{user.office}</span>
                        </div>}
                        {isCompany && rolePermissions.canViewYearHired && user.yearHired && <div className={Classes.infoPairContainer}>
                            <div className={Classes.oneLinerTitle}>Year Hired</div>
                            <span>{user.yearHired}</span>
                        </div>}
                        {!isParent && !isStaffOrSuper && rolePermissions.canViewIncomingClass && user.incomingClass && <div className={Classes.infoPairContainer}>
                            <div className={Classes.oneLinerTitle}>Incoming Class</div>
                            <span>{user.incomingClass}</span>
                        </div>}
                        {!isParent && !isStaffOrSuper && rolePermissions.canViewGraduatingClass && user.graduatingClass && <div className={Classes.infoPairContainer}>
                            <div className={Classes.oneLinerTitle}>Graduating Class</div>
                            <span>{user.graduatingClass}</span>
                        </div>}
                        {/*{!!(user.lastActive) && <div className={Classes.infoPairContainer}>
                            <span className={Classes.oneLinerTitle}>{'Last Active'}</span>
                            <span>{user.lastActive}</span>
                        </div>}*/}
                        {rolePermissions.canViewVeteran && !!user.veteran && <div className={Classes.infoPairContainer}>
                            <span className={Classes.infoLine}><StarIcon className={Classes.smallIcon} />{infoIsPrefix} a veteran</span>
                        </div>}
                        {!isParent && rolePermissions.canViewParent && !!user.parent && <div className={Classes.infoPairContainer}>
                            <span className={Classes.infoLine}><ChildIcon className={Classes.smallIcon} />{infoIsPrefix} a parent</span>
                        </div>}
                        {rolePermissions.canViewWorkRemote && !!user.workRemote && <div className={Classes.infoPairContainer}>
                            <span className={Classes.infoLine}><HomeWorkIcon className={Classes.smallIcon} />{infoIsPrefix} working remotely</span>
                        </div>}
                        {!isCompany && !isCommunity && !isSchoolOnline && !isParent && !isStaffOrSuper && rolePermissions.canViewLiveOnCampus && !!housing && !!housing.name && housing.onCampus && <div className={Classes.infoPairContainer}>
                            <span className={Classes.infoLine}><LocationCity className={Classes.smallIcon} />{campusPrefix} {` on campus${!!housing.id && !!housing.name && ` at ${housing.name}`}`}</span>
                        </div>}
                        {!isCompany && !isCommunity && !isSchoolOnline && !isParent && !isStaffOrSuper && rolePermissions.canViewLiveOnCampus && !!housing && !!housing.name && !housing.onCampus && <div className={Classes.infoPairContainer}>
                            <span className={Classes.infoLine}><LocationCity className={Classes.smallIcon} />{campusPrefix} {` off-campus${!!housing.name && ` at ${housing.name}`}`}</span>
                        </div>}
                        {!isCompany && !isCommunity && !isParent && !isStaffOrSuper && rolePermissions.canViewTransfer && !!transfer && <div className={Classes.infoPairContainer}>
                            <span className={Classes.infoLine}><RedoIcon className={Classes.smallIcon} />{studentInfoIsPrefix} a transfer student {!!transfer.name && `from ${transfer.name}`}</span>
                        </div>}
                        {!isSchoolOnline && !isParent && rolePermissions.canViewOnline && !!user.onlineStudent && <div className={Classes.infoPairContainer}>
                            <span className={Classes.infoLine}><OnlineIcon className={Classes.smallIcon} />{onlineClassPrefix} online classes</span>
                        </div>}
                        {!isStaffOrSuper && !isParent && rolePermissions.canViewTimeStatus && <div className={Classes.infoPairContainer}>
                            <span className={Classes.infoLine}><TimeLapse className={Classes.smallIcon} />{infoIsPrefix} a {user.fullTime ? 'full-time' : 'part-time'} student</span>
                        </div>}
                    </div>

                    {/* INTERESTS SECTION */}

                    {!!interests.length && <div>
                        {this.state.selectedInterest && !isMe &&
                            <InterestPopper
                                interest={this.state.selectedInterest}
                                onClose={this.handleInterestPopperClose}
                                open={this.state.interestPopperOpen}
                                anchorRef={this.state.interestAnchorRef}
                            />
                        }
                        <BigDivider />
                        <div className={Classes.sectionHeaderStyles}>
                            <FlexRow style={{ marginBottom: 22 }}>
                                <div>
                                    <h3>{titlePrefix} Interests</h3>
                                </div>
                                {showInterestHelperText && <InterestHelperText>
                                    Click any Interest below to add it to your own profile.
                                </InterestHelperText>}
                                {isMe ? <Tooltip
                                    arrow
                                    title='Edit My Interests'
                                    placement='top'
                                    data-focus-outline='radius:30,padding:-2,zIndex:1'
                                >
                                    <EditIconButton
                                        size='large'
                                        component={RouterLink}
                                        to={{
                                            pathname: '/app/profile/edit/interests',
                                            state: {
                                                returnTo: `/app/profile/${user.id}`
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </EditIconButton>
                                </Tooltip> : null}
                            </FlexRow>
                        </div>

                        <div ref={this.setUserInterestsRef}>
                            {sortedCategories.map((category) => {

                                const filteredInterests = interests.filter((interest) => interest.categoryId === category.id).sort((a, b) => {

                                    const aIsPassion = passionInterests && passionInterests.some((passionInterest) => {

                                        return passionInterest.id === a.id;
                                    });

                                    const bIsPassion = passionInterests && passionInterests.some((passionInterest) => {

                                        return passionInterest.id === b.id;
                                    });

                                    const aSpecial = a && a.special;
                                    const bSpecial = b && b.special;

                                    return (Number(bIsPassion) - Number(aIsPassion)) || (Number(bSpecial) - Number(aSpecial)) || (a.categoryName.localeCompare(b.categoryName)) || a.name.localeCompare(b.name);
                                });

                                return (
                                    <React.Fragment key={category.id}>
                                        <div className={Classes.categoryHolder}>
                                            <h4 style={{ margin: 0 }}
                                                aria-label={`Interests Category ${category.name}`}
                                            >
                                                {category.name}
                                            </h4>
                                        </div>
                                        <div className={Classes.interests}>
                                            {filteredInterests.map((interest) => {

                                                const alreadyHasInterest = currentUserInterestsIds.includes(interest.id);

                                                const isPassion = passionInterests.some((passionInterest) => {

                                                    return passionInterest.id === interest.id;
                                                });
                                                //Only allow to add inteerest on other people pages and on the inbterest that user dont have
                                                return <InterestChip
                                                    isPassion={isPassion}
                                                    handleClick={(!isMe && !alreadyHasInterest) ? (event) => {

                                                        this.handleInterestChipClick(event, interest);
                                                    } : null}
                                                    isCommonInterest={!isMe && alreadyHasInterest}
                                                    className={Classes.interest}
                                                    key={interest.id}
                                                    interest={interest}
                                                    category={category}
                                                    isMe={isMe}
                                                />;
                                            })}
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>}

                    {/* ABOUT MY STUDENT SECTION */}
                    {/* student name is at least what wee except */}

                    {isParent && (rolePermissions.canViewStudentName || rolePermissions.canViewStudentBio) && !!studentName && (
                        <div className={Classes.sectionHeaderStyles} style={{ paddingBottom: 8 }}>
                            <BigDivider />
                            <h3>About {isMe ? 'Your' : 'My'} Student</h3>
                            {rolePermissions.canViewStudentName && !!studentName && <div className={Classes.infoPairContainer}>
                                <div className={Classes.oneLinerTitle}>Name</div>
                                <span>{studentName}</span>
                            </div>}
                            {rolePermissions.canViewStudentBio && !!studentBio && <div className={Classes.infoPairContainer}>
                                <div className={Classes.oneLinerTitle}>Bio</div>
                                <span>&ldquo;{studentBio}&rdquo;</span>
                            </div>}
                            {rolePermissions.canViewMajor && user.major && <div className={Classes.infoPairContainer}>
                                <div className={Classes.oneLinerTitle}>Major</div>
                                {user.major && <span className={Classes.profileDetailLine}>{user.major}</span>}
                            </div>}
                            {rolePermissions.canViewIncomingClass && user.incomingClass && <div className={Classes.infoPairContainer}>
                                <div className={Classes.oneLinerTitle}>Incoming Class</div>
                                <span>{user.incomingClass}</span>
                            </div>}
                            {rolePermissions.canViewGraduatingClass && user.graduatingClass && <div className={Classes.infoPairContainer}>
                                <div className={Classes.oneLinerTitle}>Graduating Class</div>
                                <span>{user.graduatingClass}</span>
                            </div>}
                            {rolePermissions.canViewTransfer && !!transfer && <div className={Classes.infoPairContainer}>
                                <span className={Classes.infoLine}><RedoIcon className={Classes.smallIcon} />{user.firstName}{studentInfoIsPrefix} a transfer student {!!transfer.name && `from ${transfer.name}`}</span>
                            </div>}
                        </div>
                    )}

                    {/* COMMUNITY SECTION */}

                    {!!sortedGroups.length && canSeeUsersGroups && <div className={Classes.classesSectionContainer}>
                        <BigDivider />
                        <div className={Classes.sectionHeaderStyles}>
                            <FlexRow style={{ marginBottom: 20 }}>
                                <div>
                                    <h3>{titlePrefix} Groups</h3>
                                </div>
                                {isMe ? <Tooltip
                                    arrow
                                    title='Edit My Groups'
                                    placement='top'
                                    data-focus-outline='radius:30,padding:-2,zIndex:1'
                                >
                                    <EditIconButton
                                        size='large'
                                        onClick={this.openClassSearch}
                                    >
                                        <EditIcon />
                                    </EditIconButton>
                                </Tooltip> : null}
                            </FlexRow>
                        </div>
                        <List className={Classes.classes}>
                            {sortedGroups.map((group) => {

                                const link = gotoGroupLink(group, currentUserClasses);

                                return (
                                    <ClassListItem
                                        key={group.id}
                                        showPrivate
                                        group={group}
                                        showUnreadBadge
                                        announcementDescInTitle
                                        titleFontSize={17}
                                        link={link}
                                        data-focus-outline='zIndex:1,radius:5'
                                        middleIcon={<RightArrowIcon />}
                                        push={push}
                                    />
                                );
                            })}
                        </List>
                    </div>}

                    {/* CONNECTIONS SECTION */}

                    {!!peers.length && canSeeUsersConnections && <div className={Classes.sectionHeaderStyles}>
                        {sortedGroups.length === 0 && <BigDivider />}
                        {sortedGroups.length !== 0 && <div style={{ height: 40 }} />}
                        <h3>{titlePrefix} Connections</h3>
                        <ImageList style={{ margin: 0, overflow: 'hidden' }} rowHeight={175} cols={this.state.wide ? 3 : 2}>
                            {this.state.shuffledPeers.map((peer) => {

                                const interactionWithPeer = rolesInteractions.find((roleInteraction) => {

                                    return roleInteraction.id === peer.roleId;
                                });

                                // PREVENTING BUG PROBLEM ITS EASIER TO PREVENT TO SEE SOMEONE THAN HAVE AN ERROR IF THERE IS WRONNG SETUP IN DATABASE
                                let canViewProfile = false;
                                if (interactionWithPeer) {
                                    canViewProfile = interactionWithPeer.canViewProfile;
                                }

                                return <StyledImageListItem
                                    key={peer.id}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {canViewProfile ? (
                                        <Link
                                            to={`/app/profile/${peer.id}`}
                                            aria-label={`${peer.firstName} ${peer.lastName}'s profile`}
                                            data-focus-outline='radius:12,zIndex:1'
                                        >
                                            <img src={getSizedImageUrl(peer.croppedPicture, 400)} alt={`${peer.firstName} ${peer.lastName}`} />
                                            <StyledImageListItemBar
                                                title={
                                                    this.state.wide ?
                                                        `${peer.firstName} ${peer.lastName}` :
                                                        <span className={Classes.smallPeerName}>{`${peer.firstName} ${peer.lastName}`}</span>
                                                }
                                            />
                                        </Link>
                                    ) : <a onClick={() => {

                                        showNotification('Sorry, you cannot see this user\'s profile');
                                    }}>
                                        <img src={getSizedImageUrl(peer.croppedPicture, 400)} alt={`${peer.firstName} ${peer.lastName} profile picture`} />
                                        <StyledImageListItemBar
                                            title={
                                                this.state.wide ?
                                                    `${peer.firstName} ${peer.lastName}` :
                                                    <span className={Classes.smallPeerName}>{`${peer.firstName} ${peer.lastName}`}</span>
                                            }
                                        />
                                    </a>}
                                </StyledImageListItem>;
                            })}

                        </ImageList>
                    </div>}

                    {/* End Section*/}
                </div>
                <ManageGroupsDialog
                    open={this.state.groupManagementOpen}
                    user={user}
                    currentGroups={classes}
                    myCurrentGroups={currentUserClasses}
                    onRequestClose={this.closeGroupManagement}
                />
                <NotifyUserDialog
                    open={this.state.notifyUserOpen}
                    user={user}
                    onRequestClose={this.closeNotifyUser}
                />
                <ClassSearchDialog
                    open={this.state.searchOpen}
                    onRequestClose={this.closeClassSearch}
                />
            </Wrapper>
        );
    }
}

module.exports = DetailPage;

internals.styles = {
    icon: {
        fill: '#ffa6c4'
    }
};

internals.SpinnerContainer = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-contents: center;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.75);
    height: 10rem;
    width: 10rem;
`;

internals.BigDivider = class BigDivider extends React.PureComponent {

    static propTypes = {
        className: T.string
    }

    render() {

        return <Divider className={`${this.props.className || ''} ${Classes.bigDivider}`} />;
    }
};

internals.getPronouns = (gender) => {

    switch (gender) {
        case 'male':
            return 'He/him';
        case 'female':
            return 'She/her';
        case 'nonbinary':
            return 'They/them';
        default:
            return gender;
    }
};

internals.Wrapper = Styled.div`
    width: 100%;

    h3 {
        font-size: 28px;
        margin: 0;
        margin-bottom: 26px;
    }
    h4 {
        font-size: 23px;
        margin: 0;
    }
`;

internals.HeaderTitle = Styled(Typography).attrs({
    variant: 'h2'
})`
    && {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 32px;
        text-overflow: ellipsis;
        overflow: hidden;

        @media screen and (max-width: 600px) {
            font-size: 20px;
        }
    }
`;

internals.TopRowExtras = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: -28px;
    margin-left: -20px;
    margin-right: -5px;
`;

internals.PictureWrapper = Styled.div`
    position: relative;

    height: 250px;

    img {
      width: 100%;
      border-radius: 1em;
    }

    @media screen and (max-width: 600px) {
        height: 178px;
    }
`;

internals.ProfileFAB = Styled(FAB)`
    width: 36px;
    height: 36px;

    svg {
        fill: #ffffff;
        width: 19px;
        height: 19px;
    }
`;

internals.EditPicButtonsContainer = Styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    gap: 10px;
    padding: 7px 10px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 1.4rem;

    @media screen and (max-width: 600px) {
        bottom: 10px;
        border-radius: 1rem;
    }
`;

internals.EditIconButton = Styled(IconButton)`
    @media screen and (max-width: 600px) {
        padding: 10px;
    }
`;

internals.FlexRow = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;

    h3 {
        padding-top: 3px;
        margin-bottom: 0;
    }
`;

internals.InterestHelperText = Styled(Typography)`
    && {
        margin-top: -4px;
        margin-bottom: 18px;
        font-style: italic;
        color: #595959;
        width: 45%;

        @media screen and (max-width: 600px) {
            font-size: 16px;
            width: 40%;
        }
    }
`;

internals.styles = {
    icon: {
        fill: '#ffa6c4'
    },
    peerChatWrapper: {
        height: '4.5em'
    },
    connectButton: {
        backgroundColor: '#ffe2ec', // TODO: express as lightened color
        height: '2.25em',
        margin: '.625em 0',
        fontSize: '1.45em'
    },
    connectButtonWithSimilarities: {
        backgroundColor: '#ffe2ec', // TODO: express as lightened color
        minHeight: '4.50em',
        maxHeight: '6.50em',
        height: 'unset',
        margin: '.625em 0',
        maxWidth: '100%'
    },
    classLink: {
        textDecoration: 'none',
        display: 'inline-block',
        width: '100%'
    },
    similarityTextParagraph: {
        margin: 0,
        fontSize: '1em',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 'calc(90%)',
        color: '#000000',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        webkitBoxOrient: 'vertical',
        overflow: 'hidden',
        lineHeight: '1.5em'
    },
    chatButton: {
        backgroundColor: '#ddfbff', // TODO: express as lightened color
        height: '2.25em',
        margin: '.625em 0',
        fontSize: '1.45em'
    },
    buttonLabel: {
        fontFamily: Fonts.headerFont,
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: '1.25em',
        letterSpacing: 1,
        lineHeight: '2.5em',
        cursor: 'pointer'
    },
    buttonLabelSimilarities: {
        fontFamily: Fonts.headerFont,
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: '1.25em',
        letterSpacing: 1,
        lineHeight: '1.5em',
        flex: '1 1 auto'
    },
    smallMarginRight: {
        marginRight: '5px'
    }
};

internals.StyledBackdrop = Styled(Backdrop)`
    && {
        z-index: 2000;
        background-color: rgba(0, 0, 0, 0.2);
    }
`;

internals.StyledInterestChip = Styled(Chip)`
    && {
        height: 40px;
        padding: 5px;
        border: ${({ isCommonInterest, theme }) => isCommonInterest && `4px solid ${theme.palette.primary.main}`};
        background: ${({ isPassion }) => isPassion && 'linear-gradient(0deg, #A79CDC 0%, #DDD9F0 75%)'};
        font-family: ${({ isPassion, theme }) => isPassion && theme.typography.fonts.Mulish};
        font-weight: ${({ isPassion }) => isPassion && '700'};
        color: ${({ isPassion }) => isPassion && '#000'};
        &:focus {
            outline: auto 2px -webkit-focus-ring-color;
        }
        &.MuiChip-avatar {
            height: 36px !important;
            width: 36px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            & img {
                width: 100%;
                height: auto;
            }
        }
    }
`;

internals.CameraButton = Styled.button`
    margin: 10px;
    padding: 8px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    &.primary {
        border: none;
        background: ${({ theme }) => theme.palette.primary.main};
        color: white;
    }
    &.secondary {
        border: 1px solid #ccc;
        background: white;
    }
`;

internals.InterestChip = function InterestChip(props) {

    const {
        interest,
        category,
        isPassion,
        handleClick,
        isCommonInterest,
        isMe
    } = props;

    const { StyledInterestChip } = internals;

    const svg = (interest && interest.svg) ? interest.svg : ((category && category.svg) ? category.svg : null);

    let imageData = '';

    if (svg) {
        const buff = Buffer.from(svg);
        const base64data = buff.toString('base64');
        imageData = `data:image/svg+xml;base64,${base64data}`;
    }

    let ariaLabel = '';
    if (isPassion) {
        ariaLabel = 'Passion ';
    }

    ariaLabel += isMe ? `Interest ${interest.name}.` : `Interest ${interest.name}. ${isCommonInterest ? 'You have this interest in common.' : 'Press enter to add to your own list of interests.'}`;

    return (
        <StyledInterestChip
            avatar={imageData && <img alt={`${interest.name} icon`} src={imageData} style={{ width: 35, height: 35 }} />}
            onClick={handleClick}
            deleteIcon={!isCommonInterest && <AddCircleRoundedIcon />}
            // this  onDelete funciton is required to show delete icon
            onDelete={!isCommonInterest ? handleClick : null}
            label={interest.name}
            tabIndex={0}
            aria-label={ariaLabel}
            data-focus-outline='radius:16,inside,zIndex:1'
            isPassion={isPassion}
            {...props}
        />
    );
};

internals.InterestChip.propTypes = {
    interest: T.object,
    handleClick: T.func,
    isCommonInterest: T.bool,
    category: T.object,
    isPassion: T.bool,
    isMe: T.bool
};

internals.StyledImageListItem = Styled(ImageListItem)`
    && {
        height: auto;
        border-radius: 10px;
        overflow: hidden;
    }
`;

internals.StyledImageListItemBar = Styled(ImageListItemBar)`
    && {
        .MuiImageListItemBar-positionBottom {
            bottom: 4px;
        }
    }
`;
internals.StyledImageRequestBtn = Styled(FlatButton)`
    && {
        width: 150px;
        line-height: 32px !important;
        width: 48% !important;
        &:first-of-type {
            margin-right: 2% !important;
        }
        &:last-of-type {
            margin-left: 2% !important;
        }
    }
`;

internals.ConnectButton = Styled(FlatButton, transient$Props)`
    && {
        font-family: ${Fonts.headerFont};
        font-weight: bold;
        text-transform: none;
        font-size: 1.25em;
        letter-spacing: normal;
        line-height: 2.5em;
        height: 2.25em;
        margin: .625em 0;
        fontSize: 1.45em;
        background-color: ${({ $isChat }) => $isChat ? '#ddfbff' : '#ffe2ec'};
    }
`;

internals.getPronouns = (gender) => {

    switch (gender) {
        case 'male':
            return 'He/him';
        case 'female':
            return 'She/her';
        case 'nonbinary':
            return 'They/them';
        default:
            return gender;
    }
};
